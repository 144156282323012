
import {
  communicationMapActions,
  communicationMapState
} from "@/store/modules/communication";
import Vue from "vue";

export default Vue.extend({
  components: {
    DispatchedSingleRecipientEmailCommunication: () =>
      import(
        /* webpackChunkName: "dispatched-email-communicationn" */ "./DispatchedSingleRecipientEmailCommunication.vue"
      ),
    DispatchedMultipleRecipientsEmailCommunication: () =>
      import(
        /* webpackChunkName: "dispatched-email-communicationn-recipients" */ "./DispatchedMultipleRecipientsEmailCommunication.vue"
      )
  },
  data() {
    return {
      communication: null,
      loading: false,
      show: true,
      errorMessage: "",
      resourceNotFound: false
    };
  },
  mounted() {
    this.getCommunication(this.$route.params.communicationId).catch(
      (error: Error) => {
        this.errorMessage = error.message;
        this.resourceNotFound = true;
        this.$bugSnagClient.notify(error);
      }
    );
  },
  methods: {
    ...communicationMapActions(["getCommunication"])
  },
  computed: {
    ...communicationMapState(["editing", "makingApiRequest"])
  }
});
